import styledSystem from '@emotion/styled';
import { css } from '@emotion/core';

import colors from '../../utils/colors';

const buttonBasic = css`
  width: 100%;
  padding: 0.5rem 1.25rem;
  border-radius: 5px;
  border: 2px solid ${colors.secondary};
`;

const buttonPrimary = css`
  ${buttonBasic};
  background-color: ${colors.secondary};
  color: ${colors.primary};
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    color: ${colors.secondary};
    cursor: pointer;
  }
`;

const buttonSecondary = css`
  ${buttonBasic};
  background-color: transparent;
  color: ${colors.secondary};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.secondary};
    color: ${colors.primary};
    cursor: pointer;
  }
`;

const ButtonPrimary = styledSystem.button`
  ${buttonPrimary};
`;

export const ButtonSecondary = styledSystem.button`
  ${buttonSecondary};
`;

export const ButtonTertiary = styledSystem.button`
  ${buttonBasic};
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: ${colors.primary};
  color: ${colors.secondary};
  opacity: 0.95;
  
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.secondary};
    opacity: 1;
  }
`;

export default ButtonPrimary;
