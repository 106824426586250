/* eslint-disable no-undef, react/prop-types */
import { graphql, Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Parallax, Background } from 'react-parallax';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Helmet } from 'react-helmet';
import PageTransition from '../components/PageTransition';
import encodeForm from '../utils/encodeForm';
import feather from '../utils/feather';
import ButtonPrimary, { ButtonSecondary } from '../components/Buttons';
import { Divider1, Divider2, Divider3, Divider4, Divider4IconStyle, Divider5, Divider5IconStyle, HorizontalLine } from '../components/Dividers';
import { showSmall, hideSmall, noWrap, visuallyHidden, ulStyle, disableBrowserStyling, whiteTextShadow } from '../style/genericStyling';
import { formSubmitButton } from '../style/formStyling';
import { gbglightblueradialgradientellipse, darkblueradialgradientellipse } from '../style/backgroundGradients';
import { Box, Flex, BoxVisible, Container } from '../style/basicStyle';
import { shadedPanel, shadedPanelDark, thickerBorder } from '../style/panelStyling';
import colors from '../utils/colors';
import Hero from '../components/Hero';

class About extends Component {
  componentDidMount() {
    const { props } = this;
    props.toggleNavDark({ navBarDark: false });
  }

  render() {
    const { props } = this;
    const { data } = props;
    if (props.errors) {
      props.errors.forEach((err) => console.log('about error rendering: ', err));
      throw new Error(props.errors[0] && props.errors[0].message);
    }
    const { imageSharp } = data;
    const myData = data && data.allPageDataJson.edges[0].node;

    return (
      <PageTransition location={props.location}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>About Project Red July</title>
        </Helmet>
        <Hero
          opacity={0.5}
          toggleNavDark={props.toggleNavDark}
          navDarkActive={props.navDarkActive}
          minHeight="65vh"
          heading1={myData.title}
          heading3={myData.subtitle}
          heroImage={imageSharp.fluid.src}
          heading1-data-aos="fade-up"
          heading1-data-aos-duration="1500"
          heading3-data-aos="fade-in"
          heading3-data-aos-delay="750"
          heading3-data-aos-duration="2000"
        />
        {/* ********************************************************************************** */}
        {/* ********************************* Offices Begin*********************************** */}
        {/* ********************************************************************************** */}
        <Parallax>
          <div>
            <Flex textAlign="center" justifyContent="center" wrap={['wrap']}>
              <Box m={[2, 2, 2, 2]} py={[4, 5]} textAlign="center" width={[1, 800]}>
                <Container>
                  <Box id="locations" data-aos="fade-down" data-aos-duration="1500" width={[1]} py={[1, 0]} my={[3, 2]} textAlign="center" fontSize={[4, 4, 5, 6]}>
                    Our Locations
                  </Box>
                  <Box width={[1, 1, 1 / 2]} p={[2]}>
                    <Box data-aos-anchor="#locations" textAlign="left" p={[2]} data-aos="fade-up" data-aos-delay="250" data-aos-duration="750">
                      <Box py={[3]} fontSize={[3]}>
                        <Box p={[2]} style={{ fontWeight: 'bold' }}>
                          Project Red July
                        </Box>
                        <Box>Some Address</Box>
                        <Box>Some City</Box>
                        <Box my={[3]}>
                          <Box>
                            Tel:
                            <a href="tel:1-111-111-1111"> 111-111-1111</a>
                          </Box>
                          <Box>
                            Email:
                            <a href="mailto:info@projectredjuly.com"> info@projectredjuly.com</a>
                          </Box>
                        </Box>
                      </Box>
                      <Box py={[3]} fontSize={[3]}>
                        <Box p={[2]} style={{ fontWeight: 'bold' }}>
                          Project Red July
                        </Box>
                        <Box>Some Address</Box>
                        <Box>Some City</Box>
                        <Box>
                          Tel:
                          <a href="tel:1-111-111-1111"> 111-111-1111</a>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Flex>
          </div>
        </Parallax>
        {/* ********************************************************************************** */}
        {/* ********************************* Offices End ********************************* */}
        {/* ********************************************************************************** */}
      </PageTransition>
    );
  }
}

export const pageQuery = graphql`
  query AboutQuery {
    placeholderImage: imageSharp(original: { src: { regex: "/placeholderImage/" } }) {
      fluid(maxWidth: 1700, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    imageSharp(original: { src: { regex: "/aboutHero/" } }) {
      fluid(maxWidth: 1700, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    pageImages: allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "about" }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1700, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allPageDataJson(filter: { name: { eq: "about" } }) {
      edges {
        node {
          name
          title
          subtitle
        }
      }
    }
  }
`;

export default About;
